import React, { useEffect } from 'react';
import { cx } from '@emotion/css';

import { Text } from '@/components/typographies';
import { COLORS } from '@/styles/colors';
import Template from '@/components/useCases/Template';
import * as style from '@/pages/components/SymptomDoctors/styles';
import { useSymptomDoctorListContext } from '@/context/doctor/symptom';

import DepartmentFilterModal from '../DepartmentFilterModal';
import AccordionIcon from '../../../assets/icons/ic_accordion.svg';
import LocationSettingModal from '../LocationSettingModal';
import DoctorList from '../DoctorList';

const SymptomDoctors = () => {
  const {
    selectedDepartment,
    initDepartment,
    initRouterQuery,
    departmentDoctors,
    isDepartmentDoctorsLoading,
    openDepartmentFilterModal,
    openedDepartmentFilterModal,
    location
  } = useSymptomDoctorListContext();

  useEffect(() => {
    initDepartment();
  }, [initDepartment]);

  useEffect(() => {
    initRouterQuery();
  }, [initRouterQuery]);

  return (
    <Template.Page className={style.container}>
      <div className={style.header}>
        <div className={style.filter}>
          {selectedDepartment && (
            <div className={style.filterText} onClick={openDepartmentFilterModal}>
              <img
                className={style.departmentIcon}
                src={selectedDepartment?.image}
                alt="굿닥 비대면진료 증상"
              />
              <Text type="body1_500">{selectedDepartment?.name}</Text>
              <AccordionIcon
                className={style.accordionIcon}
                color={COLORS.GRAY_100}
                alt="굿닥 비대면진료 화살표"
              />
            </div>
          )}
          <div
            className={cx(style.filterText, style.filterSpace)}
            onClick={location.openLocationSettingModal}
          >
            <Text type="body1_500">
              {location.selectedLocation.keyword || '지역전체'}
            </Text>
            <AccordionIcon
              className={style.accordionIcon}
              color={COLORS.GRAY_100}
              alt="굿닥 비대면진료 화살표"
            />
          </div>
        </div>

        {openedDepartmentFilterModal && <DepartmentFilterModal />}
        {location.openedLocationSettingModal && <LocationSettingModal {...location} />}
      </div>
      <DoctorList
        doctors={departmentDoctors?.untactDepartmentsDoctors[0]?.doctors || []}
        isLoading={isDepartmentDoctorsLoading}
      />
    </Template.Page>
  );
};
export default SymptomDoctors;
