import constate from 'constate';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';

import {
  MedicalTypeEnum,
  UntactDoctorSortEnum,
  useUntactDepartmentsDoctorsQuery,
  useUntactDepartmentsQuery
} from '@/graphql/generated/graphql';
import useLocation from '@/context/doctor/hooks/useLocation';
import { Department } from '@/context/doctor/types';

const useSymptomDoctorList = () => {
  const router = useRouter();
  const { departmentId } = router.query;
  const location = useLocation();
  const [queryId] = useState(departmentId || '11');
  const [selectedDepartment, setSelectedDepartment] = useState<Department>();
  const [openedDepartmentFilterModal, setOpenedDepartmentFilerModal] = useState(false);

  const { data: departments, loading: isDepartmentLoading } = useUntactDepartmentsQuery({
    variables: {
      input: {
        types: [
          MedicalTypeEnum.Medicine,
          MedicalTypeEnum.KoreanMedicine,
          MedicalTypeEnum.DentalMedicine
        ]
      }
    }
  });

  const sortedDepartments = useMemo(() => {
    if (!departments?.untactDepartments) return [];
    return [...departments?.untactDepartments].sort((previous, current) => {
      return previous.displayOrder - current.displayOrder;
    });
  }, [departments]);

  const handleDepartment = (department: Department) => {
    setSelectedDepartment(department);
    closeDepartmentFilterModal();
  };

  const openDepartmentFilterModal = () => {
    setOpenedDepartmentFilerModal(!openedDepartmentFilterModal);
  };

  const closeDepartmentFilterModal = () => {
    setOpenedDepartmentFilerModal(false);
  };

  const { data: departmentDoctors, loading: isDepartmentDoctorsLoading } =
    useUntactDepartmentsDoctorsQuery({
      variables: {
        input: {
          untactDepartmentIds: [String(selectedDepartment?.id)],
          sortType: UntactDoctorSortEnum.Distance,
          longitude: Number(location.selectedLocation.longitude),
          latitude: Number(location.selectedLocation.latitude),
          limit: 100
        }
      }
    });

  const initDepartment = useCallback(() => {
    if (!!sortedDepartments) {
      if (queryId) {
        return setSelectedDepartment(
          sortedDepartments.find((department) => department.id == queryId)
        );
      }
    }
  }, [sortedDepartments, queryId]);

  const initRouterQuery = useCallback(async () => {
    if (!selectedDepartment) return;
    await router.replace(`${router.pathname}`, {
      query: { ...router.query, departmentId: selectedDepartment?.id }
    });
  }, [selectedDepartment]);

  return {
    departmentDoctors,
    isDepartmentDoctorsLoading,
    sortedDepartments,
    isDepartmentLoading,
    handleDepartment,
    openedDepartmentFilterModal,
    openDepartmentFilterModal,
    selectedDepartment,
    setSelectedDepartment,
    departmentId,
    initDepartment,
    initRouterQuery,
    location
  };
};
export const [SymptomDoctorListProvider, useSymptomDoctorListContext] =
  constate(useSymptomDoctorList);
