import React, { useEffect } from 'react';

import amplitude from '@/utils/amplitude';
import SymptomDoctors from '@/pages/components/SymptomDoctors';
import { SymptomDoctorListProvider } from '@/context/doctor/symptom';

const Index = () => {
  useEffect(() => {
    amplitude.track('untact_addOnDepartmentList_view');
  }, []);

  return (
    <SymptomDoctorListProvider>
      <SymptomDoctors />
    </SymptomDoctorListProvider>
  );
};

export default Index;
