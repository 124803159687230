import { css } from '@emotion/css';

import { COLORS } from '@/styles/colors';

export const container = css`
  padding: 8px;
  border-radius: 8px;
  max-height: 280px;
  width: calc(640px - 40px);
  max-width: calc(100% - 40px);
  overflow-y: auto;
  background-color: ${COLORS.WHITE};
  box-shadow: 0 2px 1px rgba(22, 24, 29, 0.02), 0 2px 8px rgba(22, 24, 29, 0.1);
  @media (min-width: 640px) {
    max-height: none;
  }
`;

export const departmentCardContainer = css`
  display: grid;
  column-gap: 8px;
  row-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const lottieContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

export const lottie = css`
  width: 100%;
  height: 50px;
`;

export const departmentCard = css`
  &:hover,
  &:active {
    background: ${COLORS.GRAY_20};
  }
`;

export const checkIcon = css`
  margin-left: 6px;
`;
