import React from 'react';
import Lottie from 'react-lottie-player';

import { COLORS } from '@/styles/colors';
import {
  checkIcon,
  container,
  departmentCard,
  departmentCardContainer,
  lottie,
  lottieContainer
} from '@/pages/components/DepartmentFilterModal/styles';
import { DepartmentCard } from '@/components/useCases';
import LoadingLottie from '@/assets/lotties/loading.json';
import { useSymptomDoctorListContext } from '@/context/doctor/symptom';

import CheckIcon from '../../../assets/icons/ic_check.svg';

const DepartmentFilterModal = () => {
  const { sortedDepartments, handleDepartment, selectedDepartment, isDepartmentLoading } =
    useSymptomDoctorListContext();

  return (
    <div className={container}>
      {isDepartmentLoading ? (
        <div className={lottieContainer}>
          <Lottie loop play animationData={LoadingLottie} className={lottie} />
        </div>
      ) : (
        <div className={departmentCardContainer}>
          {sortedDepartments?.map((department) => (
            <DepartmentCard
              key={department.id}
              department={department}
              className={departmentCard}
              onClick={() => handleDepartment(department)}
            >
              {department.id === selectedDepartment?.id && (
                <CheckIcon
                  width={16}
                  height={16}
                  color={COLORS.GRAY_80}
                  className={checkIcon}
                  alt="굿닥 비대면진료 과목 선택"
                />
              )}
            </DepartmentCard>
          ))}
        </div>
      )}
    </div>
  );
};
export default DepartmentFilterModal;
