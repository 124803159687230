import { css } from '@emotion/css';

import { COLORS } from '@/styles/colors';

export const container = css`
  background-color: ${COLORS.WHITE};
`;

export const header = css`
  z-index: 999;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 640px;
  max-width: 100%;
`;
export const filter = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: 48px;
  padding: 8px 20px;
  background-color: ${COLORS.WHITE};
  border-bottom: 1px solid ${COLORS.GRAY_30};
  cursor: pointer;
`;

export const filterSpace = css`
  margin-left: 8px;
`;
export const filterText = css`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  &:hover,
  &:active {
    background: ${COLORS.GRAY_20};
  }
`;

export const accordionIcon = css`
  margin-left: 6px;
`;
export const departmentIcon = css`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`;
